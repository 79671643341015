import Job from './pages/job/Job';

function App() {
  return (
    <div className="App">
      <Job />
    </div>
  );
}

export default App;
