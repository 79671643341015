import React, { useState } from "react";
import { Table } from "antd";
import JobDetails from "./JobDetails";
import HeaderItem from "../../component/headerContent/HeaderItem";

const columns = [
  {
    title: "ID",
    dataIndex: "id",
    width: "10%",
  },
  {
    title: "Status",
    dataIndex: "status",
    width: "10%",
  },
  {
    title: "Customer",
    dataIndex: "customer",
    width: "10%",
  },
  {
    title: "Fixelist",
    dataIndex: "fixelist",
    width: "10%",
  },
  {
    title: "Posted Date Time",
    dataIndex: "posted",
    sorter: (a, b) => a.posted.localeCompare(b.posted),
  },
  {
    title: "Scheduled Date Time",
    dataIndex: "scheduled",
    sorter: (a, b) => a.scheduled.localeCompare(b.scheduled),
  },
  {
    title: "Complete Date Time",
    dataIndex: "complete",
    sorter: (a, b) => a.complete.localeCompare(b.complete),
  },
];
const data = [
  {
    id: "AC#049108",
    status: "CANCELED",
    customer: "hath",
    fixelist: "Ckeditor",
    posted: "03/10/2023 11:31 PM",
    scheduled: "05/03/2023 18:31 PM",
    complete: "15/05/2023 06:31 AM",
  },
  {
    id: "PL#049110",
    status: "COMPLETE",
    customer: "cuong",
    fixelist: "Metatechnolody",
    posted: "16/09/2023 12:31 AM",
    scheduled: "03/10/2023 11:31 PM",
    complete: "03/10/2023 11:31 PM",
  },
  {
    id: "IT#049109",
    status: "CANCELED",
    customer: "hoai",
    fixelist: "Dbuilder",
    posted: "03/10/2023 11:31 PM",
    scheduled: "03/10/2023 11:31 PM",
    complete: "03/10/2023 11:31 PM",
  },
  {
    id: "AC#049109",
    status: "COMPLETE",
    customer: "sadd",
    fixelist: "Dbuilder",
    posted: "03/10/2023 11:31 PM",
    scheduled: "03/10/2023 11:31 PM",
    complete: "03/10/2023 11:31 PM",
  },
  {
    id: "PL#049109",
    status: "CANCELED",
    customer: "asdassd",
    fixelist: "MetaTechnology",
    posted: "03/10/2023 11:31 PM",
    scheduled: "03/10/2023 11:31 PM",
    complete: "03/10/2023 11:31 PM",
  },
  {
    id: "IT#049109",
    status: "COMPLETE",
    customer: "hath",
    fixelist: "Ckeditor",
    posted: "03/10/2023 11:31 PM",
    scheduled: "03/10/2023 11:31 PM",
    complete: "03/10/2023 11:31 PM",
  },
  {
    id: "PL#049109",
    status: "COMPLETE",
    customer: "hath",
    fixelist: "Ckeditor",
    posted: "03/10/2023 11:31 PM",
    scheduled: "03/10/2023 11:31 PM",
    complete: "03/10/2023 11:31 PM",
  },
  {
    id: "IT#049109",
    status: "CANCELED",
    customer: "hath",
    fixelist: "Ckeditor",
    posted: "03/10/2023 11:31 PM",
    scheduled: "03/10/2023 11:31 PM",
    complete: "03/10/2023 11:31 PM",
  },
  {
    id: "AC#049109",
    status: "CANCELED",
    customer: "hath",
    fixelist: "Ckeditor",
    posted: "03/10/2023 11:31 PM",
    scheduled: "05/03/2023 18:31 PM",
    complete: "15/05/2023 06:31 AM",
  },
  {
    id: "AC#049109",
    status: "CANCELED",
    customer: "hath",
    fixelist: "Ckeditor",
    posted: "03/10/2023 11:31 PM",
    scheduled: "05/03/2023 18:31 PM",
    complete: "15/05/2023 06:31 AM",
  },
  {
    id: "AC#049109",
    status: "CANCELED",
    customer: "hath",
    fixelist: "Ckeditor",
    posted: "03/10/2023 11:31 PM",
    scheduled: "05/03/2023 18:31 PM",
    complete: "15/05/2023 06:31 AM",
  },
];
const onChange = (pagination, filters, sorter, extra) => {
  console.log("params", pagination, filters, sorter, extra);
};

const JobContent = () => {
  const [selectedRow, setSelectedRow] = useState(null);
  const [showTable, setShowTable] = useState(true); // Trạng thái để ẩn/hiển thị bảng

  const handleRowClick = (record) => {
    setSelectedRow(record);
    setShowTable(false); // Ẩn bảng khi xem chi tiết
  };

  const handleDetailClose = () => {
    setSelectedRow(null);
    setShowTable(true); // Hiển thị lại bảng khi đóng chi tiết
  };

  return (
    <div>
      {showTable && (
        <div>
          <HeaderItem />
          <Table
            style={{ marginTop: "1%" }}
            columns={columns}
            dataSource={data}
            onChange={onChange}
            onRow={(record) => ({
              onClick: () => handleRowClick(record),
            })}
          />
        </div>
      )}
      {selectedRow &&
        !showTable && ( // Hiển thị chi tiết nếu selectedRow không null và showTable là false
          <JobDetails selectedRow={selectedRow} onClose={handleDetailClose} />
        )}
    </div>
  );
};

export default JobContent;
