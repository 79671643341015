import React, { useState } from "react";
import { Button, Form, Table } from "antd";
import { ReloadOutlined, DownloadOutlined } from "@ant-design/icons";
import "./HeaderItem.css";

const HeaderItem = () => {
  return (
      <Form layout="inline" className="form">
        <Form.Item >
          <span className="firstItem">Search Result</span>
        </Form.Item>
        <Form.Item>
          <Button type="primary"><DownloadOutlined /> EXPORT</Button>
          <Button style={{marginLeft: "15px"}}><ReloadOutlined /></Button>
        </Form.Item>
      </Form>
  );
};

export default HeaderItem;
