import React, { useState } from "react";
import { Button, Form, Input, Select, DatePicker } from "antd";

const Search = () => {
  return (
    <Form layout="inline">
      <Form className="firstItem" layout="inline">
        <Form.Item label="ID">
          <Input placeholder="Enter ID" />
        </Form.Item>
        <Form.Item label="Status">
          <Select placeholder="COMPLETE">
            <Select.Option value="1">COMPLETE</Select.Option>
            <Select.Option value="2">CANCELED</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="User">
          <Input placeholder="Enter User" />
        </Form.Item>
        <Form.Item label="Date">
          <DatePicker />
        </Form.Item>
      </Form>

      <Form className="form" layout="inline">
        <Form.Item>
          <Button type="primary">Search</Button>
        </Form.Item>
        <Form.Item>
          <Button>EXPAND</Button>
        </Form.Item>
      </Form>
    </Form>
  );
};

export default Search;
